import Header from "../Header/Header";
import styles from "./page.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateForward } from "@fortawesome/free-solid-svg-icons";

interface IPage {
    showNav: boolean;
    isLoading?: boolean;
    hideContentDuringLoading?: boolean;
    children?: React.ReactNode;
}

const Page = ({ showNav, isLoading, hideContentDuringLoading = true, children }: IPage) => (
    <div className={styles["page"]}>
        <Header showNav={showNav}></Header>
        <div className={styles["content"]}>
            {(!isLoading || !hideContentDuringLoading) && <>{children}</>}
            {isLoading && (
                <div className={styles["loading"]}>
                    <FontAwesomeIcon icon={faRotateForward} spin={true} size={"2x"} />
                </div>
            )}
        </div>
        <div className={styles["footer"]}>
            {isLoading && hideContentDuringLoading ? (
                <></>
            ) : (
                <>© {new Date().getFullYear()} Llama Stories, All rights reserved</>
            )}
        </div>
    </div>
);

export default Page;
