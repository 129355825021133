import CountryPicker from "../country-picker/CountryPicker";
import styles from "./nav.module.scss";

const Nav = () => {
    return (
        <div className={styles["nav"]}>
            <a className={styles["menu-item"]} href="/">
                Stories
            </a>
            <a className={styles["menu-item"]} href="/art">
                Llama Art
            </a>
            <a className={styles["menu-item"]} href="/store">
                Store
            </a>
            <a className={styles["menu-item"]} href="/about">
                About
            </a>
            <a className={styles["menu-item"]} href="/terms">
                Terms
            </a>
            <a className={styles["menu-item"]} href="/contact">
                Contact
            </a>

            <CountryPicker />
        </div>
    );
};

export default Nav;
