import styles from "./banner.module.scss";
import { useCallback, useState, useEffect } from "react";
import { StoryArt, storyApiInstance as API } from "../../../Api/Story";
import Picture from "../../aiart/picture";
import { useNavigate } from "react-router-dom";

const Banner = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [list, setList] = useState<StoryArt[]>([]);
    const navigate = useNavigate();

    const loadImages = useCallback(async () => {
        setIsLoading(true);

        const data = await API.ListRandomArt(8);

        setList(data.list);

        setIsLoading(false);
    }, [list]);

    useEffect(() => {
        loadImages();
    }, []);

    return (
        <div className={styles["banner"]} onClick={() => navigate("/art")}>
            {list.map((image, index) => (
                <div key={index} className={styles["art"]}>
                    <Picture token={image.token} />
                </div>
            ))}
            {!isLoading && <div className={styles["overlay"]}>Explore Llama Art</div>}
        </div>
    );
};

export default Banner;
