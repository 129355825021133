import styles from "./social.module.scss";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faLink } from "@fortawesome/free-solid-svg-icons";

const Social = ({ fullUrl }: { fullUrl: string }) => {
    return (
        <div className={styles["row-social"]}>
            <a href={`https://www.facebook.com/sharer/sharer.php?display=popup&u=${fullUrl}`}>
                <div className={styles["social-button"]}>
                    <FontAwesomeIcon icon={faFacebook} />
                </div>
            </a>
            <a
                href={`https://twitter.com/intent/tweet?url=${fullUrl}&text=Llama%20story%20of%20the%20day.`}
            >
                <div className={styles["social-button"]}>
                    <FontAwesomeIcon icon={faTwitter} />
                </div>
            </a>
            <a href={`mailto:?subject=&body=Llama%20story%20of%20the%20day: ${escape(fullUrl)}`}>
                <div className={styles["social-button"]}>
                    <FontAwesomeIcon icon={faEnvelope} />
                </div>
            </a>
            <a href={`##`} onClick={() => navigator.clipboard.writeText(fullUrl)}>
                <div className={styles["social-button"]}>
                    <FontAwesomeIcon icon={faLink} />
                </div>
            </a>
        </div>
    );
};

export default Social;
