import { useCallback, useEffect, useState } from "react";
import { Story, storyApiInstance as API } from "../../Api/Story";
import Page from "../../Component/Page/Page";
import styles from "./home.module.scss";
import Article from "../../Component/article";
import Banner from "./banner";

const HOME = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [stories, setStories] = useState<Story[]>([]);
    const [page, setPage] = useState<number>(1);
    const [totalpages, setTotalpages] = useState<number>(1);
    const [country, setCountry] = useState<string>("");

    const loadList = useCallback(async () => {
        setIsLoading(true);

        const productData = await API.List(page);

        setStories([...stories, ...productData.list]);

        setPage(page + 1);
        setTotalpages(Math.ceil(productData.quantity / 2));
        setCountry(productData.country);

        setIsLoading(false);
    }, [page]);

    const handleScroll = useCallback(() => {
        if (
            Math.ceil(window.innerHeight + window.scrollY) <
                document.documentElement.scrollHeight - 100 ||
            page > totalpages ||
            isLoading
        ) {
            return;
        }
        loadList();
    }, [isLoading, loadList, page, totalpages]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [handleScroll, isLoading]);

    useEffect(() => {
        loadList();
    }, []);

    return (
        <Page showNav={true} isLoading={isLoading} hideContentDuringLoading={false}>
            <div className={styles["orders"]}>
                <div className={styles["banner"]}>
                    <Banner />
                </div>
                <div className={styles["order-list"]}>
                    {stories.map((story, index) => (
                        <Article key={index} {...story} country={country} />
                    ))}
                </div>
            </div>
        </Page>
    );
};

export default HOME;
