import { StylesConfig } from "react-select";
import "./bootstrap.css";

import Router from "./Router/Router";

export const selectStyles: StylesConfig = {
    menu: (baseStyles) => ({
        ...baseStyles,
        marginTop: "0",
        border: "0.1rem solid var(--bg-color)",
        borderRadius: 0
    }),
    control: (baseStyles) => ({
        ...baseStyles,
        boxShadow: "none",
        border: "0.1rem solid teal",
        borderColor: "teal !important",
        borderRadius: "var(--border-radius)",
        outline: "none",
        opacity: "0.8",
        height: "2.6rem",
        transition: "opacity 0.2s",
        ":hover": {
            opacity: "1"
        }
    }),
    menuList: (base) => ({
        ...base,
        padding: 0,
        "::-webkit-scrollbar": {
            width: "4px",
            height: "0px"
        },
        "::-webkit-scrollbar-track": {
            background: "white"
        },
        "::-webkit-scrollbar-thumb": {
            background: "teal"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "teal"
        }
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        backgroundColor: state.isSelected ? "teal" : state.isFocused ? "teal" : "white",
        color: state.isSelected || state.isFocused ? "white" : "black",
        cursor: "pointer"
    }),
    indicatorSeparator(base, state) {
        return {
            ...base,
            backgroundColor: state.isDisabled ? "teal" : "teal",
            width: "2px",
            opacity: "0.8"
        };
    },

    valueContainer(base) {
        return {
            ...base,
            border: "none",
            cursor: "pointer"
        };
    },
    dropdownIndicator(base, state) {
        return {
            ...base,
            cursor: "pointer",
            color: state.isDisabled ? "teal" : "teal",
            opacity: "0.8",
            transition: "opacity 0.2s",
            ":hover": {
                opacity: "1"
            }
        };
    }
};

const App = () => {
    return (
        <div className="App">
            <Router />
        </div>
    );
};

export default App;
