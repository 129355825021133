import styles from "./article.module.scss";
import "react-datepicker/dist/react-datepicker.css";
import { Story, StoryArt, storyApiInstance } from "../../Api/Story";
import Social from "./social";
import Affiliate from "../affiliate";
import Picture from "../../Page/aiart/picture";
import { useState, useCallback, useEffect, useMemo } from "react";
import { apiCountry } from "../../Api/BaseApi";

interface props extends Story {
    country: string;
}

const Article = ({ asin, story, date, id, name, country }: props) => {
    const url = useMemo(() => `/story?s=${btoa(`${asin}-${id}`)}`, [asin, id]);
    const fullUrl = useMemo(() => `https://www.llamastories.com${url}`, [url]);

    const [art, setArt] = useState<StoryArt | null>(null);

    const loadImages = useCallback(async () => {
        const data = await storyApiInstance.ListRandomArt(1);
        if (data && data.list && data.list.length > 0) setArt(data.list[0]);
    }, []);

    useEffect(() => {
        loadImages();
    }, []);

    return (
        <div className={styles["article"]} key={asin}>
            <Social fullUrl={fullUrl} />
            <div className={styles["row-content"]}>
                <a className={styles["title"]} href={url}>
                    <h3 className={styles["date"]}>{new Date(date).toLocaleDateString()}</h3>{" "}
                </a>
                <div className={styles["text"]}>
                    <div className={styles["art"]}>
                        {art && <Picture token={art.token} discord_user={art.discord_user} />}
                    </div>
                    {story.split("\n").map((paragraph, index) => (
                        <p key={index}>{paragraph}</p>
                    ))}
                </div>
                {/*<Affiliate name={name} asin={asin} showDisclaimer={true} country={country} />*/}
            </div>
            <div className={styles["row-sidebar"]}>
                {/*apiCountry === "US" && (
                    <>
                        {Math.random() > 0.5 ? (
                            <>
                                <a
                                    target="_blank"
                                    href="https://www.amazon.com/hz/audible/mlp/membership/plus?ref_=assoc_tag_ph_1524216631897&_encoding=UTF8&camp=1789&creative=9325&linkCode=pf4&tag=brenda093-20&linkId=7181af5828cc2f7a9e6534c0b0dfa144"
                                    rel="noreferrer noopener"
                                >
                                    <img
                                        alt="audible"
                                        src="https://images-na.ssl-images-amazon.com/images/G/01/Audible/en_US/images/creative/Minerva-Plus-Associate-300x250-V08.png"
                                    />
                                </a>
                            </>
                        ) : (
                            <>
                                <a
                                    target="_blank"
                                    href="https://www.amazon.com/gp/video/primesignup?ref_=assoc_tag_ph_1402131641212&_encoding=UTF8&camp=1789&creative=9325&linkCode=pf4&tag=brenda093-20&linkId=fdc0447f932924d72ee112573441efde"
                                    rel="noreferrer noopener"
                                >
                                    <img
                                        alt="amazon prime"
                                        src="https://images-na.ssl-images-amazon.com/images/G/01/digital/video/merch/associates/AssocAds_GleasonSVOD_300x250.jpg"
                                    />
                                </a>
                            </>
                        )}
                    </>
                        )*/}
            </div>
        </div>
    );
};

export default Article;
