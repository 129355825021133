import styles from "./mobile-nav.module.scss";
import { slide as Menu } from "react-burger-menu";
import menuStyles from "./menu.styles";
import CountryPicker from "../country-picker/CountryPicker";

const MobileNav = () => {
    return (
        <div className={styles["nav"]}>
            <Menu pageWrapId={"page-wrap"} right styles={menuStyles}>
                <CountryPicker />
                <a className={styles["menu-item"]} href="/">
                    Stories
                </a>
                <a className={styles["menu-item"]} href="/art">
                    Llama Art
                </a>
                <a className={styles["menu-item"]} href="/store">
                    Store
                </a>
                <a className={styles["menu-item"]} href="/about">
                    About
                </a>
                <a className={styles["menu-item"]} href="/terms">
                    Terms
                </a>
                <a className={styles["menu-item"]} href="/contact">
                    Contact
                </a>
            </Menu>
        </div>
    );
};

export default MobileNav;
