import styles from "./header.module.scss";
import MobileNav from "../Nav/MobileNav";
import Nav from "../Nav/Nav";
import CountryPicker from "../country-picker/CountryPicker";

interface IHeader {
    showNav: boolean;
}

const Header = (props: IHeader) => {
    return (
        <div className={styles["header"]}>
            <div className={styles["content-wrapper"]}>
                <div className={styles["content"]}>
                    <a href="/">
                        <object
                            className={styles["banner"]}
                            data={`https://file.llamastories.com/llamastoriesbanner.png`}
                            type="image/png"
                        >
                            <img
                                alt={"Llama Stories"}
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                            ></img>
                        </object>
                    </a>
                    {props.showNav && <Nav></Nav>}
                </div>
            </div>
            <div className={styles["subheader-wrapper"]}>
                <div className={styles["subheader"]}>
                    <div>
                        <b>Welcome</b> to the best spot in the Llama Kingdom, check out our{" "}
                        <b>Llama art</b>! Keep in mind that we may make money from the product
                        affiliate links.
                    </div>
                </div>
            </div>
            {props.showNav && <MobileNav></MobileNav>}
        </div>
    );
};

export default Header;
