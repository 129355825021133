import { BrowserRouter, Routes, Route } from "react-router-dom";
import HOME from "../Page/home";
import STORY from "../Page/story";
import AIART from "../Page/aiart";
import TERMS from "../Page/terms";
import ABOUT from "../Page/about";
import CONTACT from "../Page/contact";
import STORE from "../Page/store";

export default function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={<HOME />}></Route>
                <Route path="/art" element={<AIART />}></Route>
                <Route path="/about" element={<ABOUT />}></Route>
                <Route path="/terms" element={<TERMS />}></Route>
                <Route path="/contact" element={<CONTACT />}></Route>
                <Route path="/story" element={<STORY />}></Route>
                <Route path="/store" element={<STORE />}></Route>
            </Routes>
        </BrowserRouter>
    );
}
