import Page from "../../Component/Page/Page";
import styles from "./contact.module.scss";

const CONTACT = () => {
    return (
        <Page showNav={true}>
            <div className={styles["contact"]}>
                <h3 className={styles["title"]}>Contact Us</h3>
                <div>
                    <p>
                        We love getting emails from readers. Please feel free to write to
                        contact@llamastories.com
                    </p>
                </div>
            </div>
        </Page>
    );
};

export default CONTACT;
