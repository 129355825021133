import styles from "./picture.module.scss";

const Picture = ({ discord_user, token }: { discord_user?: string; token: string }) => {
    return (
        <div className={styles["art"]}>
            <img alt="art" src={`https://file.llamastories.com/art-${token}.png`} />
            {discord_user && <span>by {discord_user}</span>}
        </div>
    );
};

export default Picture;
