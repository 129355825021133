import Page from "../../Component/Page/Page";
import styles from "./about.module.scss";

const ABOUT = () => {
    return (
        <Page showNav={true}>
            <div className={styles["about"]}>
                <h3 className={styles["title"]}>Llama Stories</h3>
                <div>
                    <p>
                        Founded in 2023, Llama Stories is a digital art gallery that reates unique
                        digital art pieces and mindblowing reading material. We are a team of
                        artists, engineers, and art enthusiasts who are passionate about the future
                        of art and technology. We believe that art is a form of expression that
                        should be accessible to everyone. We are committed to creating a platform
                        that allows artists to showcase their work and connect with art llama lovers
                        around the world.
                    </p>
                    <p>
                        We are very happy that you are here. Thank you for supporting us and our
                        art.
                    </p>
                </div>

                <h3 id="contact" className={styles["title"]}>
                    Contact Us
                </h3>
                <div>
                    <p>
                        We love getting emails from readers. Please feel free to write to
                        contact@llamastories.com
                    </p>
                </div>
            </div>
        </Page>
    );
};

export default ABOUT;
