import { apiCountry, get, TResult } from "./BaseApi";

export interface Story {
    asin: string;
    story: string;
    id: number;
    date: Date;
    name: string;
}

export interface StoryArt {
    id: number;
    date: Date;
    token: string;
    discord_user: string;
}

export interface StoryAffiliate {
    asin: string;
    message: string;
    channel: string;
    name: string;
    date: Date;
}

interface IStoryList {
    list: Story[];
    quantity: number;
    country: string;
}

interface IStorySingle {
    list: Story[];
    country: string;
}

interface IStoryAffiliateSingle {
    list: StoryAffiliate[];
    country: string;
}
interface IStoryArtList {
    list: StoryArt[];
    quantity: number;
}

class StoryApi {
    public async getAffiliate(): Promise<IStoryAffiliateSingle> {
        const apiResult = await get<TResult<IStoryAffiliateSingle>>(
            `story/${apiCountry}/affiliate/`
        );

        if (apiResult.status === 200) {
            return apiResult.data.result;
        } else {
            return {
                list: [],
                country: ""
            };
        }
    }

    public async getLocation(): Promise<IStorySingle> {
        const apiResult = await get<TResult<IStorySingle>>(`story/location`);

        if (apiResult.status === 200) {
            return apiResult.data.result;
        } else {
            return {
                list: [],
                country: ""
            };
        }
    }

    public async get(id: number): Promise<IStorySingle> {
        const apiResult = await get<TResult<IStorySingle>>(`story/get/${id}`);

        if (apiResult.status === 200) {
            return apiResult.data.result;
        } else {
            return {
                list: [],
                country: ""
            };
        }
    }

    public async List(page: number): Promise<IStoryList> {
        const apiResult = await get<TResult<IStoryList>>(
            `story/${apiCountry}/list?page=${page === null ? "" : page}`
        );

        if (apiResult.status === 200) {
            return apiResult.data.result;
        } else {
            return {
                list: [],
                quantity: 0,
                country: ""
            };
        }
    }

    public async ListArt(page: number): Promise<IStoryArtList> {
        const apiResult = await get<TResult<IStoryArtList>>(
            `story/${apiCountry}/art/list?page=${page === null ? "" : page}`
        );

        if (apiResult.status === 200) {
            return apiResult.data.result;
        } else {
            return {
                list: [],
                quantity: 0
            };
        }
    }

    public async ListRandomArt(quantity: number): Promise<IStoryArtList> {
        const apiResult = await get<TResult<IStoryArtList>>(`story/randomart/${quantity}`);

        if (apiResult.status === 200) {
            return apiResult.data.result;
        } else {
            return {
                list: [],
                quantity: 0
            };
        }
    }
}

export const storyApiInstance = new StoryApi();
