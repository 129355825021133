import { useCallback, useEffect, useState } from "react";
import styles from "./country-picker.module.scss";
import Select from "react-select";
import { selectStyles } from "../../App";
import { storyApiInstance } from "../../Api/Story";
import { apiCountry } from "../../Api/BaseApi";

export type TOption = {
    value: string;
    label: React.ReactNode;
};

const countryOptions: TOption[] = [
    { value: "US", label: "United States" },
    { value: "CA", label: "Canada" },
    { value: "UK", label: "United Kingdom" }
];

const urlOption = countryOptions.find((option) => option.value === apiCountry) || countryOptions[0];

const CountryPicker = () => {
    const [selectedCountry, setSelectedCountryValue] = useState<TOption>(urlOption);

    const handleSetCountry = useCallback((option: TOption) => {
        setSelectedCountryValue(option);

        setTimeout(() => {
            if (window.location.href.indexOf("llamastories") >= 0) {
                window.location.href = `https://${option.value.toLocaleLowerCase()}.llamastories.com`;
            }
        }, 100);
    }, []);

    useEffect(() => {
        const getLocation = async () => {
            const apiLocation = await storyApiInstance.getLocation();

            if (apiLocation.country) {
                if (apiLocation.country === "CA") {
                    handleSetCountry(countryOptions[1]);
                } else if (apiLocation.country === "GB") {
                    handleSetCountry(countryOptions[2]);
                } else {
                    handleSetCountry(countryOptions[0]);
                }
            } else {
                handleSetCountry(countryOptions[0]);
            }
            console.log(apiLocation);
        };

        if (
            window.location.href.indexOf("https://www.llamastories") >= 0 ||
            window.location.href.indexOf("https://llamastories") >= 0
        ) {
            getLocation();
        }
    }, [handleSetCountry]);

    return (
        <div className={styles["picker"]}>
            <Select
                className={styles["select"]}
                options={countryOptions}
                value={selectedCountry}
                isSearchable={false}
                onChange={(value) => {
                    //@ts-ignore
                    handleSetCountry(value);
                }}
                styles={selectStyles}
            />
        </div>
    );
};

export default CountryPicker;
