import axios from "axios";
import { TResult, get, post } from "./BaseApi";

export interface ProductOption {
    item_code: string;
    name: string;
    colours: [{ name: string; image: string }];
}

export interface ProductRequest {
    image_url: string;
    colours: string;
    item_code: string;
}

class TeemillApi {
    public async getOptions(): Promise<ProductOption[]> {
        const options = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
            url: `https://teemill.com/omnis/v3/product/options/`
        };

        const returnPayload = await axios(options);

        if (returnPayload.status === 200) {
            const formattedData = returnPayload.data.data.map((product: ProductOption) => {
                const colours = Object.keys(product.colours).map((colour) => {
                    return {
                        name: colour,
                        //@ts-ignore
                        image: product.colours[colour]
                    };
                });

                return {
                    ...product,
                    colours
                };
            });

            return formattedData;
        } else {
            return [];
        }
    }

    public async getProduct(product: ProductRequest): Promise<any> {
        const apiResult = await post<TResult<any>>(`story/teemill`, {
            image_url: product.image_url,
            colours: product.colours,
            item_code: product.item_code
        });

        if (apiResult.status === 200) {
            return apiResult.data.result;
        } else {
            return {
                list: [],
                quantity: 0
            };
        }
    }
}

export const TeemillApiInstance = new TeemillApi();
