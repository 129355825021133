import Page from "../../Component/Page/Page";
import styles from "./terms.module.scss";

const TERMS = () => {
    return (
        <Page showNav={true}>
            <div className={styles["terms"]}>
                <h3 className={styles["title"]}>Copyright</h3>
                <div>
                    All images and text on this blog are created by our curators and credited to
                    llamastories.com, unless otherwise stated. Unauthorized use and/or duplication
                    of this material (including translations) without written permission is
                    prohibited. Excerpts (no longer than one paragraph) and links to this blog are
                    permitted, providing that full credit is given to llamastories.com with clear
                    and specific direction to the original content. ©{new Date().getFullYear()}{" "}
                    Llama Stories. – All Rights Reserved.
                </div>

                <h3 className={styles["title"]}>Disclosure</h3>
                <div>
                    llamastories.com will, at times, accept forms of compensation or participate in
                    affiliate relationships for approved partners. This means that when I provide a
                    link to a product you can purchase from these websites, I receive a small
                    percentage at no extra cost to you.
                </div>

                <h3 className={styles["title"]}>Privacy</h3>
                <div>
                    Anonymous visitor information is recorded and used by llamastories.com to obtain
                    blog statistics. This information is not shared or sold. Google Analytics
                    records which browser you use, how you found this blog, and tracks
                    hourly/daily/weekly/monthly visitors. When you contact me via email, your email
                    address will not be shared with anyone. I am not responsible for the privacy
                    practices of any of my advertisers.
                </div>
            </div>
        </Page>
    );
};

export default TERMS;
