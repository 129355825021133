import axios from "axios";
import qs from "qs";
import { Credentials } from "../Store/Credentials";

export const baseUrl = "https://api.llamastories.com/";
//export const baseUrl = "http://localhost:8080/";

type TReturn<T> = {
    data: T;
    status: number;
};

export const apiCountry =
    window.location.href.indexOf("ca.llamastories") >= 0
        ? "CA"
        : window.location.href.indexOf("uk.llamastories") >= 0
        ? "UK"
        : "US";

export type TResult<T> = {
    result: T;
};

const formatReturn = <T>(returnPayload: any): TReturn<T> => {
    return {
        data: returnPayload.data,
        status: returnPayload.status
    };
};

export const get = async <T>(
    url: string,
    authorized: boolean = true,
    isExternal = false
): Promise<TReturn<T>> => {
    const options = {
        method: "GET",
        headers: {
            Authorization: authorized ? `Bearer ${Credentials.bearerToken}` : ""
        },
        url: isExternal ? url : baseUrl + url
    };

    try {
        const returnPayload = formatReturn<T>(await axios(options));
        return returnPayload;
    } catch (e: any) {
        console.log(e);
        if (e.response.status === 401) {
            Credentials.LogOut();
        }
        return { data: null as T, status: e.response.status };
    }
};

export const post = async <T>(
    url: string,
    data: any,
    authorized: boolean = true,
    redirectsWhenError: boolean = true,
    isExternal = false
): Promise<TReturn<T>> => {
    const options = {
        method: "POST",
        data: qs.stringify(data),
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: authorized ? `Bearer ${Credentials.bearerToken}` : ""
        },
        url: isExternal ? url : baseUrl + url
    };

    try {
        const returnPayload = formatReturn<T>(await axios(options));
        return returnPayload;
    } catch (e: any) {
        console.log(e);
        if (e.response.status === 401 && redirectsWhenError) {
            Credentials.LogOut();
        }
        return { data: null as T, status: e.response.status };
    }
};

export const put = async <T>(
    url: string,
    data: any,
    authorized: boolean = true
): Promise<TReturn<T>> => {
    const options = {
        method: "PUT",
        data: qs.stringify(data),
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: authorized ? `Bearer ${Credentials.bearerToken}` : ""
        },
        url: baseUrl + url
    };

    try {
        const returnPayload = formatReturn<T>(await axios(options));
        return returnPayload;
    } catch (e: any) {
        console.log(e);
        if (e.response.status === 401) {
            Credentials.LogOut();
        }
        return { data: null as T, status: e.response.status };
    }
};
