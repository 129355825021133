import { useCallback, useState, useEffect } from "react";
import Page from "../../Component/Page/Page";
import styles from "./aiart.module.scss";
import { StoryArt, storyApiInstance as API, StoryAffiliate } from "../../Api/Story";
import Affiliate from "../../Component/affiliate";
import Picture from "./picture";

const AIART = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [list, setList] = useState<(StoryArt | StoryAffiliate)[]>([]);
    const [country, setCountry] = useState<string>("");

    const [page, setPage] = useState<number>(1);
    const [totalpages, setTotalpages] = useState<number>(1);

    const loadList = useCallback(async () => {
        setIsLoading(true);

        const data = await API.ListArt(page);
        const affiliateData = await API.getAffiliate();

        setList([...list, ...data.list, ...affiliateData.list]);
        setCountry(affiliateData.country);

        setPage(page + 1);
        setTotalpages(Math.ceil(data.quantity / 6));

        setIsLoading(false);
    }, [list, page]);

    const handleScroll = useCallback(() => {
        if (
            Math.ceil(window.innerHeight + window.scrollY) <
                document.documentElement.scrollHeight - 100 ||
            page > totalpages ||
            isLoading
        ) {
            return;
        }
        loadList();
    }, [isLoading, loadList, page, totalpages]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [handleScroll, isLoading]);

    useEffect(() => {
        loadList();
    }, []);

    return (
        <Page showNav={true} isLoading={isLoading} hideContentDuringLoading={false}>
            <div className={styles["aiart"]}>
                <div className={styles["art-list"]}>
                    {list.map((item, index) => (
                        <div className={styles["row"]} key={index}>
                            {"token" in item ? (
                                <Picture discord_user={item.discord_user} token={item.token} />
                            ) : (
                                <>
                                    {/*<Affiliate
                                    name={item.name}
                                    asin={item.asin}
                                    showDisclaimer={false}
                                    country={country}
                            />*/}
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </Page>
    );
};

export default AIART;
