import styles from "./picture.module.scss";

const Picture = ({
    discord_user,
    token,
    onClick
}: {
    discord_user?: string;
    token: string;
    onClick: () => void;
}) => {
    return (
        <div className={styles["container"]} onClick={onClick}>
            <div className={styles["art"]}>
                <img alt="art" src={`https://file.llamastories.com/art-${token}.png`} />

                {discord_user && <span>by {discord_user}</span>}
            </div>

            <div className={styles["order"]}>Order</div>
        </div>
    );
};

export default Picture;
