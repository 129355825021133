import { useCallback, useEffect, useState } from "react";
import { Story, storyApiInstance as API } from "../../Api/Story";
import Page from "../../Component/Page/Page";
import Article from "../../Component/article";
import { useSearchParams } from "react-router-dom";

const STORY = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("s");
    const [country, setCountry] = useState<string>("");

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [story, setStory] = useState<Story | null>(null);

    const load = useCallback(async (storyId: number) => {
        setIsLoading(true);

        const productData = await API.get(storyId);
        if (productData.list.length === 1) setStory(productData.list[0]);
        setCountry(productData.country);
        setIsLoading(false);
    }, []);

    useEffect(() => {
        try {
            const decoded = atob(token?.toString() || "");

            const storyId = decoded.split("-")[1];

            if (storyId) {
                load(+storyId);
            }
        } catch (e) {}
    }, [token, load]);

    return (
        <Page showNav={true} isLoading={isLoading}>
            {story ? <Article {...story} country={country} /> : <>Not Found</>}
        </Page>
    );
};

export default STORY;
